.split{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: 60%;
    flex: 20;
}
.split-first {
    display: flex;
    flex-direction: row
}
.modal-action-div{
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: flex-end;
}