#customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    border:0.5px solid #efefef
  }
  
  #customers td, #customers th {
    /* border: 0.px solid #ddd; */
    padding: 8px;
    text-align: center;
  }
  #customers tr {
    height: 20px;

  }
  
  #customers tr:nth-child(even){background-color: #f7f7f7;}
  
  #customers tr:hover {background-color: #ddd;}
  
  #customers th {
    padding-top: 12px;
    padding-bottom: 5px;
    text-align: left;
    background-color: #FFFFFF;
    border:1px solid #e8e8e8;
    color: black;
    text-align: center;
  }