.con {
  height: 200px;
  width: 1200px;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: "1%";
}
.con::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}
.con::-webkit-scrollbar-track {
  padding: 2px 0;
  
  background-color: #fff;
}
.con::-webkit-scrollbar-thumb {
  border-radius: 10px;
  
  
  background-color: #acacac;
  
}
