.BLUE_THEME {
  display: flex;
  flex-direction: row;
  height: 65px;
  background-color: #fafafa;
}
.sideblue {
  flex: 0 0 150px;
  background-color: blue;
  padding-top: 50;
}
.TEAL_THEME {
  display: flex;
  flex-direction: row;
  height: 65px;
  background-color: #fafafa;
}
.sideteal {
  flex: 0 0 150px;
  background-color: red;
  padding-top: 30;
}
.ORANGE_THEME {
  display: flex;
  flex-direction: row;
  height: 65px;
  background-color: #fafafa;
}
.sideorange {
  flex: 0 0 150px;
  background-color: orange;
  padding-top: 30;
}

.sidebar {
  margin-top: -15;
  display: flex;
  border-radius: 0;
  height: calc(100% - 70px);
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.auth-inner {
  max-width: 500;
  margin: auto;
  background: #ffffff;
  /* box-shadow: 1px 1px 1px 1px rgb(255, 213, 213); */
  padding: 40px 55px 45px 55px;
  border-radius: 8px;
  transition: all 0.3s;
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}
